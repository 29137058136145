import React, { memo, useContext } from "react";

import {
  Autocomplete,
  Box,
  Collapse,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import CampoDoc from "../../../components/CampoDoc";
import DocumentoCelda from "../../../components/DocumentoCelda";
import Stocks from "../../Stocks/Stocks";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket, 
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const [proveedoresData, setproveedoresData] = useState([]);
  const [productosData, setProductosData] = useState([]);
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Bodegas");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  const [expandir, setexpandir] = useState(false);
  const [cargando, setcargando] = useState(false);

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.prodNombre.trim() === "" ||
      item.numFactura.trim() === "" ||
      item.documento_proveedor.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "compras",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
        cod_empresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "compras",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "compras",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario + "/+/" + row[campoExport],
          estEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVA
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 1,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled:
                row.apoTipo === "" ||
                row.apoAporte === "" ||
                row.apoValidacion === "true",
              Icon: DeleteForever,
              color: "error",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled:
                row.apoTipo === "" ||
                row.apoAporte === "" ||
                row.apoValidacion === "true",
              Icon: Create,
              color: "secondary",
              id: 3,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 4,
              ocultar: !editar || cargando,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 5,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: row.stock_total_por_compra,
              funcion: () => {
                setexpandir(!expandir);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "primary",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            // {
            //   tooltip: "Estado",
            //   texto: "",
            //   funcion: (e) => {
            //     setitem({
            //       ...item,
            //       distribuido_en_bodegas: e.target.checked ? 1 : 0,
            //     });
            //   },
            //   disabled: !editar || cargando,
            //   Icon: "",
            //   color: "primary",
            //   id: 6,
            //   ocultar: row.eliminado,
            //   tipo: "check",
            //   estado: editar
            //     ? item.distribuido_en_bodegas === 1
            //     : row.distribuido_en_bodegas === 1,
            //   variante: "",
            //   size: "small",
            //   sx: butonIconTransition,
            // },
          ]}
        />

        {editar ? (
          <>
            <>
              {" "}
              <TableCell size="small" align="center" className={claseCelda}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={productosData.map((item) => {
                      return item.prodNombre + "/+/" + item.prodId;
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", " ").split("/+/")[0];
                    }}
                    value={item.prodNombre.trim() === "" ? "" : item.prodNombre}
                    paperprops
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const options = newValue.split("/+/");

                        setitem({
                          ...item,
                          prodNombre: options[0],
                          compra_prodId: options[2],
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        onChange={async (e) => {
                          if (e.target.value === "") {
                            return mostrarAlerta("Ingrese datos", "error");
                          }
                          try {
                            const res = await clienteAxios.get(
                              `productos/getBySearch/serie_tipo_nombre?search=${e.target.value}`
                            );

                            setProductosData(res.data);
                          } catch (error) {
                            console.log({ error });
                            setProductosData([]);

                            return mostrarAlerta("No hay proveedores", "error");
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>{" "}
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  fullWidth
                  size="small"
                  value={item.tipo}
                  name="tipo"
                  onChange={(e) => handleChange(e)}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  {["INGRESO", "AJUSTE"].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
              </TableCell>
              <TableCell
                fontSize="small"
                padding="none"
                align="center"
                className={claseCelda}
              >
                <CampoDoc
                  cargando={cargando}
                  setcargando={setcargando}
                  propiedad={"documento"}
                  item={item}
                  setitem={setitem}
                  celda={true}
                  claseCelda={claseCelda}
                />
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <FormControl fullWidth>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={proveedoresData.map((item) => {
                      return (
                        item.cliDocNumero +
                        "/+/" +
                        item.cliRazonSocial +
                        "/+/" +
                        item.cliId
                      );
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", " ").split("/+/")[0];
                    }}
                    value={
                      `${item.razonSocial_proveedor} ${item.documento_proveedor}`.trim() ===
                      ""
                        ? ""
                        : `${item.razonSocial_proveedor} ${item.documento_proveedor}`
                    }
                    paperprops
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const options = newValue.split("/+/");

                        setitem({
                          ...item,
                          documento_proveedor: options[0],
                          razonSocial_proveedor: options[1],
                          id_proveedor: options[2],
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        onChange={async (e) => {
                          if (e.target.value === "") {
                            return mostrarAlerta("Ingrese datos", "error");
                          }
                          try {
                            const res = await clienteAxios.get(
                              `/proveedores/filtro/0?search=${e.target.value}`
                            );

                            setproveedoresData(res.data.data);
                          } catch (error) {
                            console.log({ error });
                            setproveedoresData([]);

                            return mostrarAlerta("No hay proveedores", "error");
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  value={item.numFactura}
                  name="numFactura"
                  onChange={(e) => handleChange(e)}
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  type="date"
                  value={item.fecFactura.substr(0, 10)}
                  name="fecFactura"
                  onChange={(e) => handleChange(e)}
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell fontSize="small" align="center" className={claseCelda}>
                {item.user}
              </TableCell>
              <TableCell fontSize="small" align="center" className={claseCelda}>
                {item.fec_upd}
              </TableCell>
            </>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {`${row.prodNombre}`}
            </TableCell>{" "}
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.tipo}
            </TableCell>
            <DocumentoCelda
              Icono={<PictureAsPdf fontSize="small" color="error" />}
              propiedad={row.documento}
              claseCelda={claseCelda}
            />
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {`${row.documento_proveedor} ${row.razonSocial_proveedor}`}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.numFactura}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.fecFactura.split("T")[0]}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.user}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {new Date(row.fec_upd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        )}
      </TableRow>{" "}
      <TableRow>
        <TableCell sx={{ margin: 0, padding: 0, pl: 3 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box mt={0.5}>
              <Divider textAlign="left">STOCK</Divider>
              <Stocks
                desdeProductos={true}
                id_compra={row.id_compra}
                producto={{
                  prodId: row.compra_prodId,
                  prodNombre: row.prodNombre,
                  prodTipo: row.prodTipo,
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
