import React, { memo, useContext, useMemo } from "react";
import MuiImageSlider from "mui-image-slider";
import { useState } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { DropzoneDialog } from "react-mui-dropzone";

// MATERIAL
import {
  Box,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Popper,
  MenuItem,
  Typography,
  Dialog,
  Autocomplete,
} from "@mui/material";
import {
  Cancel,
  Check,
  ExpandLess,
  ExpandMore,
  Photo,
  Visibility,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import Cantidades from "../Cantidades/Cantidades";
import { v4 } from "uuid";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";

const RowAgregarArma = (props) => {
  const {
    setagregar,
    setitem,
    item,
    bodegaData,
    vestimenta,
    armas,
    municion,
  } = props;
  const classes = useRowStyles();
  const claseCelda = `${classes.celda}`;
  const [open, setOpen] = React.useState(false);
  const [imagenes, setimagenes] = useState([]);
  const [productosData, setproductosData] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [expandido, setexpandido] = useState(false);
  const { mostrarAlerta } = useContext(AlertaContext);

  const [itemHijoNuevo, setitemHijoNuevo] = useState({
    T_disponible: 0,
    bodega: bodegaData.length > 0 ? bodegaData[0].bodeNombre : "",
    idBodega: bodegaData.length > 0 ? bodegaData[0].bodeId : "",
    prod_distri_Id: 0,
    prodId: 1,
    cantidad: 1,
    fotos: [],
    prodTipo: vestimenta
      ? "VESTIMENTAS"
      : armas
      ? "ARMAS"
      : municion
      ? "MUNICIONES"
      : "",
    prodSerie: "",
    prodNombre: "",
    prodMarca: "",
    prodModelo: "",
    prodTalla: "",
    prodColor: "",
    prodTipoArma: "",
    prodCalibre: "",
    prodMaterial: "",
    prodPrecioUnitario: 0,
  });
  const itemHijoDefecto = useMemo(() => {
    const defaultObject = {
      T_disponible: 0,
      bodega: bodegaData.length > 0 ? bodegaData[0].bodeNombre : "",
      idBodega: bodegaData.length > 0 ? bodegaData[0].bodeId : "",
      prod_distri_Id: 0,
      prodId: 1,
      cantidad: 1,
      fotos: [],
      prodTipo: vestimenta
        ? "VESTIMENTAS"
        : armas
        ? "ARMAS"
        : municion
        ? "MUNICIONES"
        : "",
      prodSerie: "",
      prodNombre: "",
      prodMarca: "",
      prodModelo: "",
      prodTalla: "",
      prodColor: "",
      prodTipoArma: "",
      prodCalibre: "",
      prodMaterial: "",
      prodPrecioUnitario: 0,
    };
    setproductosData([]);
    setitemHijoNuevo(defaultObject);
    return defaultObject;
  }, [bodegaData, vestimenta, armas, municion]);

  const botonDisabled = () => {
    if (
      itemHijoNuevo.nombre === "" ||
      itemHijoNuevo.marca === "" ||
      itemHijoNuevo.modelo === "" ||
      itemHijoNuevo.talla === "" ||
      itemHijoNuevo.color === ""
      // ||
      // itemHijoNuevo.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregar(false);
        setitemHijoNuevo(itemHijoDefecto);
      },
      disabled: cargando,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: async () => {
        await handleAgregar();
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Ver fotos",
      texto: "",
      funcion: async () => {
        setOpen(true);
      },
      disabled: false,
      Icon: Photo,
      color: "primary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Expandir",
      texto: "",
      funcion: async () => {
        setexpandido((prev) => !prev);
      },
      disabled: false,
      Icon: expandido ? ExpandLess : ExpandMore,
      color: "primary",
      id: 2,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const handleAgregar = async () => {
    try {
      const existe = item.distri_productos.some(
        (productoS) => productoS.prodId === itemHijoNuevo.prodId
      );

      if (existe) {
        return mostrarAlerta(
          "Este producto ya se encuentra registrado",
          "error"
        );
      }
      setcargando(true);
      let resDocumentos = {
        data: {
          urls: itemHijoNuevo.fotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(imagenes, itemHijoNuevo.fotos);
      }

      const distri_productos = [
        {
          ...itemHijoNuevo,
          fotos: resDocumentos.data.urls,
        },
        ...item.distri_productos,
      ];
      console.log(distri_productos);

      setitem({
        ...item,
        distri_productos,
      });
      setimagenes([]);
      setcargando(false);
      setagregar(false);
      setitemHijoNuevo(itemHijoDefecto);
    } catch (error) {
      setcargando(false);
    }
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <DropzoneDialog
          dialogProps={{ maxWidth: "lg" }}
          dialogTitle={
            <Typography variant="h5" color="primary">
              Subir archivos
            </Typography>
          }
          dropzoneText={
            <>
              <Typography variant="h4" color="primary">
                Arrastra una imagen o da click aqui.
              </Typography>
            </>
          }
          acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
          cancelButtonText={"Cancelar"}
          submitButtonText={"Aceptar"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            setimagenes(files);
            setOpen(false);
          }}
          showPreviewsInDropzone={true}
          showPreviews={false}
          showAlerts={true}
          showFileNamesInPreview={false}
        />
        <ButtonsAction botones={botones} className={claseCelda} />

        {/* las demas columnas */}
        <>
          <Dialog
            open={Openimagen}
            onClose={() => setOpenimagen(false)}
            aria-labelledby="draggable-dialog-title"
          >
            {itemHijoNuevo.fotos === 0 ? null : (
              <MuiImageSlider
                images={itemHijoNuevo.fotos.map((item) => item.url)}
                fitToImageHeight={true}
              />
            )}
          </Dialog>
          <TableCell size="small" align="left" className={claseCelda}>
            {itemHijoNuevo.fotos.length === 0 ? (
              "No hay imagenes"
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding="none"
                alignItems="center"
              >
                <IconButton
                  aria-label=""
                  size="small"
                  onClick={() => setOpenimagen(true)}
                  className={classes.margin}
                >
                  <Visibility size="small" color="secondary" />
                </IconButton>
              </Box>
            )}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={bodegaData.map((item) => {
                  return item.bodeNombre + "/+/" + item.bodeId;
                })}
                getOptionLabel={(option) => {
                  return option.split("/+/")[0];
                }}
                id="controlled-demo"
                value={itemHijoNuevo.bodega}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      bodega: options[0],
                      idBodega: options[1],
                    });
                    setproductosData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          {/* {itemHijoNuevo.prodTipo} */}
          {vestimenta && (
            <TableCell size="small" align="left" className={claseCelda}>
              <TextField
                fullWidth
                size="small"
                id="prodTipo"
                value={itemHijoNuevo.prodTipo}
                name="prodTipo"
                onChange={async (e) => {
                  setproductosData([]);
                  setitemHijoNuevo({
                    ...itemHijoNuevo,
                    prodTipo: e.target.value,
                  });
                }}
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["VESTIMENTAS", "EQUIPOS"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
          )}
          <TableCell size="small" align="left" className={claseCelda}>
            <FormControl fullWidth>
              <Autocomplete
                disabled={
                  !itemHijoNuevo.idBodega ||
                  (!itemHijoNuevo.prodTipo && vestimenta)
                }
                PopperComponent={PopperMy}
                disableClearable={true}
                options={productosData.map((item) => {
                  return (
                    item.prodNombre +
                    "/+/" +
                    item.prodSerie +
                    "/+/" +
                    item.T_disponible +
                    "/+/" +
                    item.prodId
                  );
                })}
                getOptionLabel={(option) => {
                  return option
                    .replace("/+/", "** ")
                    .replace("/+/", "** ")
                    .split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  `${itemHijoNuevo.prodNombre} / ${itemHijoNuevo.prodSerie}`.trim() ===
                  "/"
                    ? ""
                    : `${itemHijoNuevo.prodNombre} / ${itemHijoNuevo.prodSerie}`
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    console.log({ newValue });
                    const [, , , prodId] = newValue.split("/+/");

                    const itemF = productosData.find(
                      (itemProducto) => itemProducto.prodId === Number(prodId)
                    );
                    console.log(itemF);

                    setitemHijoNuevo({
                      ...itemHijoNuevo,
                      prodTipo: itemF.prodTipo,
                      prodSerie: itemF.prodSerie,
                      prodNombre: itemF.prodNombre,
                      prodMarca: itemF.prodMarca,
                      prodModelo: itemF.prodModelo,
                      prodTalla: itemF.prodTalla,
                      prodColor: itemF.prodColor,
                      prodTipoArma: itemF.prodTipoArma,
                      prodCalibre: itemF.prodCalibre,
                      prodMaterial: itemF.prodMaterial,
                      prodPrecioUnitario: itemF.prodPrecioUnitario,
                      cantidad: 1,
                      T_disponible: itemF.T_disponible,
                      prodId: itemF.prodId,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      try {
                        console.log(
                          `/productos/search/para/distribucion?tipo_producto=${itemHijoNuevo.prodTipo}&id_bodega=${itemHijoNuevo.idBodega}&search=${e.target.value}`
                        );

                        const res = await clienteAxios.get(
                          `/productos/search/para/distribucion?tipo_producto=${itemHijoNuevo.prodTipo}&id_bodega=${itemHijoNuevo.idBodega}&search=${e.target.value}`
                        );
                        console.log({ res }, itemHijoNuevo.prodTipo);

                        setproductosData(res.data);
                      } catch (error) {
                        console.log({ error });
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              fullWidth
              type="number"
              disabled={
                !itemHijoNuevo.idBodega &&
                !itemHijoNuevo.prodTipo &&
                !itemHijoNuevo.prodNombre
              }
              size="small"
              value={itemHijoNuevo.cantidad}
              onChange={(e) => {
                console.log(itemHijoNuevo.T_disponible);

                setitemHijoNuevo({
                  ...itemHijoNuevo,
                  cantidad: Math.max(
                    Math.min(
                      itemHijoNuevo.T_disponible,
                      e.target.valueAsNumber
                    ),
                    1
                  ).toFixed(2),
                });
              }}
            />
          </TableCell>
          {vestimenta && (
            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijoNuevo.prodTalla}
            </TableCell>
          )}
          {(armas || vestimenta) && (
            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijoNuevo.prodColor}
            </TableCell>
          )}
          <TableCell size="small" align="center" className={claseCelda}>
            ${itemHijoNuevo.prodPrecioUnitario}
            {` ** `}$
            {(
              itemHijoNuevo.prodPrecioUnitario * itemHijoNuevo.cantidad
            ).toFixed(2)}
          </TableCell>
        </>
      </TableRow>
      {/* <Cantidades
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoNuevo}
        setitem={setitemHijoNuevo}
      /> */}
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
