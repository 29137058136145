import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import RowAgregarProveedor from "./RowsProveedor/RowAgregarProveedor";
import RowProveedor from "./RowsProveedor/RowProveedor";
import { AddCircle, Download } from "@mui/icons-material";
import { Autocomplete, Button, FormControl } from "@mui/material";
import RowAgregarMovimiento from "./RowsMovimiento/RowAgregarMovimiento";
import RowMovimiento from "./RowsMovimiento/RowMovimiento";
import RowAgregarMantenimiento from "./RowsMantenimiento/RowAgregarMantenimiento";
import RowMantenimiento from "./RowsMantenimiento/RowMantenimiento";
import Dropzone from "../../components//Dropzone";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  TableHead,
  Table,
  Paper,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { Cancel, CloudUpload, DeleteForever } from "@mui/icons-material";
import TablaCabecera from "../../components/TablaCabecera";
import ToolBarTable from "../../components/ToolBarTable";
import { useModalStyle } from "../../styles/styleModal";
import RowStock from "./Stock/RowStock";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import RowAgregarStock from "./Stock/RowAgregarStock";
import { objectDefault } from "../data";
import CampoDoc from "../../components/CampoDoc";
import Compras from "../../Configuración/Compras/Compras";
import Stocks from "../../Configuración/Stocks/Stocks";
const ModalAsistencia = (props) => {
  const [bodegaData, setbodegaData] = useState(null);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
  } = props;

  const [error, seterror] = useState({});
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  // eslint-disable-next-line
  const {
    prodTipo,
    prodSerie,
    prodNombre,
    prodEstado,
    prodObservacion,
    prodMaterial,
    prodPrecioUnitario,
    prodMarca,
    prodModelo,
    prodTalla,
    prodClase,
    prodTipoArma,
    prodCalibre,
    prodColor,
    prodUso,
    prodStatus,
    condiciones,
    prodFecValidacion,
    prodFecCaducidad,

    //TAB 2
    prodPermisoPdf,
    prodComprobantePdf,
    prodDocumento,
    prodFotos,
  } = item;

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});

    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();

  const validacion = () => {
    if (prodTipo === "VESTIMENTAS" || prodTipo === "EQUIPOS") {
      if (
        prodPrecioUnitario === "" ||
        prodPrecioUnitario === 0 ||
        prodSerie.trim() === "" ||
        prodNombre.trim() === "" ||
        // prodProductoEstado.trim() === "" ||
        prodMarca.trim() === "" ||
        prodModelo.trim() === "" ||
        prodTalla === "" ||
        prodColor.trim() === ""
      ) {
        seterror({
          // seccion DatosGenerales
          prodPrecioUnitario:
            prodPrecioUnitario === "" || prodPrecioUnitario === 0,
          prodSerie: prodSerie.trim() === "" ? true : false,
          prodNombre: prodNombre.trim() === "" ? true : false,
          // prodProductoEstado: prodProductoEstado.trim() === "" ? true : false,
          prodMarca: prodMarca.trim() === "" ? true : false,
          prodModelo: prodModelo.trim() === "" ? true : false,
          prodTalla: prodTalla === "" ? true : false,
          prodColor: prodColor.trim() === "" ? true : false,
          // seccion DatosProcedencia
        });
        return true;
      } else {
        return false;
      }
    }

    if (prodTipo === "ARMAS") {
      if (
        prodPrecioUnitario === "" ||
        prodPrecioUnitario === 0 ||
        prodSerie.trim() === "" ||
        prodNombre.trim() === "" ||
        // prodProductoEstado.trim() === "" ||
        prodMarca.trim() === "" ||
        prodModelo.trim() === "" ||
        prodClase.trim() === "" ||
        prodTipoArma.trim() === "" ||
        prodCalibre.trim() === "" ||
        prodColor.trim() === "" ||
        prodUso.trim() === "" ||
        prodStatus.trim() === ""
      ) {
        seterror({
          // seccion DatosGenerales
          prodPrecioUnitario:
            prodPrecioUnitario === "" || prodPrecioUnitario === 0,
          prodSerie: prodSerie.trim() === "" ? true : false,
          prodNombre: prodNombre.trim() === "" ? true : false,
          // prodProductoEstado: prodProductoEstado.trim() === "" ? true : false,
          prodMarca: prodMarca.trim() === "" ? true : false,
          prodModelo: prodModelo.trim() === "" ? true : false,
          prodClase: prodClase.trim() === "" ? true : false,
          prodTipoArma: prodTipoArma.trim() === "" ? true : false,
          prodCalibre: prodCalibre.trim() === "" ? true : false,
          prodColor: prodColor.trim() === "" ? true : false,
          prodUso: prodUso.trim() === "" ? true : false,
          prodStatus: prodStatus.trim() === "" ? true : false,
          // seccion DatosProcedencia
        });
        return true;
      } else {
        return false;
      }
    }
    if (prodTipo === "MUNICIONES") {
      if (
        prodSerie.trim() === "" ||
        prodNombre.trim() === "" ||
        prodPrecioUnitario === "" ||
        prodPrecioUnitario === 0 ||
        // prodProductoEstado.trim() === "" ||
        prodMarca.trim() === "" ||
        prodClase.trim() === "" ||
        prodMaterial.trim() === "" ||
        prodCalibre.trim() === ""
      ) {
        const error = {
          prodSerie: prodSerie.trim() === "" ? true : false,
          prodNombre: prodNombre.trim() === "" ? true : false,
          prodPrecioUnitario:
            prodPrecioUnitario === "" || prodPrecioUnitario === 0,
          // prodProductoEstado: prodProductoEstado.trim() === "" ? true : false,
          prodMarca: prodMarca.trim() === "" ? true : false,
          prodClase: prodClase.trim() === "" ? true : false,
          prodMaterial: prodMaterial.trim() === "" ? true : false,
          prodCalibre: prodCalibre.trim() === "" ? true : false,
        };

        seterror(error);
        return true;
      } else {
        return false;
      }
    }
  };
  const [seccionActive, setseccionActive] = useState("PRINCIPAL");

  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    // if (

    //   Number(totalStock) !==

    // ) {
    //   return mostrarAlerta(
    //     `El stock debe ser igual a la cantidad del proveedor: ${Number(
    //       totalStock

    //       .map((proveedor) => proveedor.cantidad)
    //       .reduce(reducer, 0)}`,
    //     "error"
    //   );
    // }
    try {
      setcargando(true);

      const resFotos = await subirImagenes(imagenes, prodFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          prodFotos: resFotos,

          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario,
          prodEmpresa: usuario.rucempresa,
          tabla: "productos",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      const resFotos = await subirImagenes(imagenes, prodFotos);
      console.log({
        ...item,
        prodFotos: resFotos,
        condiciones_old: ObjectoActivo.condiciones,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        prodUser: usuario.usuario,
        prodEmpresa: usuario.rucempresa,
        tabla: "productos",
      });

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          prodFotos: resFotos,
          condiciones_old: ObjectoActivo.condiciones,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario,
          prodEmpresa: usuario.rucempresa,
          tabla: "productos",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar " : "Agregar "}{" "}
                  {prodTipo === "EQUIPOS" ? "EQUIPO" : prodTipo}
                </Typography>
                <Typography variant="body1" color="initial" align="center">
                  {item.prodSerie} - {item.stock_disponible}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={seccionActive === "PRINCIPAL" ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color={
                      prodTipo === "VESTIMENTAS" || prodTipo === "EQUIPOS"
                        ? error.prodPrecioUnitario ||
                          error.prodSerie ||
                          error.prodNombre ||
                          error.prodProductoEstado ||
                          error.prodMarca ||
                          error.prodModelo ||
                          error.prodTalla ||
                          error.prodColor
                          ? "error"
                          : "secondary"
                        : prodTipo === "ARMAS"
                        ? error.prodPrecioUnitario ||
                          error.prodSerie ||
                          error.prodNombre ||
                          error.prodProductoEstado ||
                          error.prodMarca ||
                          error.prodModelo ||
                          error.prodClase ||
                          error.prodTipoArma ||
                          error.prodCalibre ||
                          error.prodColor ||
                          error.prodUso ||
                          error.prodStatus
                          ? "error"
                          : "secondary"
                        : prodTipo === "MUNICIONES"
                        ? error.prodSerie ||
                          error.prodNombre ||
                          error.prodPrecioUnitario ||
                          error.prodProductoEstado ||
                          error.prodMarca ||
                          error.prodClase ||
                          error.prodMaterial ||
                          error.prodCalibre
                          ? "error"
                          : "secondary"
                        : "primary"
                    }
                    onClick={() => setseccionActive("PRINCIPAL")}
                  >
                    PRINCIPAL
                  </Button>
                  {editar && (
                    <Button
                      variant={seccionActive === "COMPRAS" ? "contained" : null}
                      disableElevation
                      className={clases.buttonTab}
                      color={"primary"}
                      onClick={() => setseccionActive("COMPRAS")}
                    >
                      COMPRAS
                    </Button>
                  )}{" "}
                  <Button
                    variant={
                      seccionActive === "DOCUMENTOS" ? "contained" : null
                    }
                    disableElevation
                    className={clases.buttonTab}
                    color={"primary"}
                    onClick={() => setseccionActive("DOCUMENTOS")}
                  >
                    DOCUMENTOS
                  </Button>
                  {/* {editar && (
                    <Button
                      variant={seccionActive === "STOCK" ? "contained" : null}
                      disableElevation
                      className={clases.buttonTab}
                      color={"primary"}
                      onClick={() => setseccionActive("STOCK")}
                    >
                      STOCK 
                    </Button>
                  )} */}
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={prodEstado === "ACTIVA" ? true : false}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            prodEstado: e.target.checked
                              ? "ACTIVA"
                              : "INACTIVA",
                          });
                        }}
                        name="antoine"
                        color="success"
                      />
                    }
                    label="Estado"
                  />
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionActive === "PRINCIPAL" ? (
                  <>
                    <>
                      {prodTipo === "VESTIMENTAS" || prodTipo === "EQUIPOS" ? (
                        <>
                          {/* : "",+ // text */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodSerie"
                            label="Serie"
                            value={prodSerie}
                            name="prodSerie"
                            error={error.prodSerie}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "",+ // TEXTAREA */}
                          <TextField
                            multiline
                            rows={1}
                            className={clases.formD}
                            size="small"
                            id="prodNombre"
                            label="Nombre"
                            value={prodNombre}
                            name="prodNombre"
                            error={error.prodNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NU+EVO", // SELECT => NUEVO,USADO */}
                          {/* <TextField
            className={clases.formD}
            size="small"
            id="prodProductoEstado"
            label="Producto Estado"
            value={prodProductoEstado}
            name="prodProductoEstado"
            error={error.prodProductoEstado}
            onChange={(e) => handleChange(e)}
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
              (item) => {
                return (
                  <MenuItem value={item}>{item} </MenuItem>
                );
              }
            )}
          </TextField> */}
                          {/* : "",+ */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="prodPrecioUnitario"
                            label="Precio Unitario"
                            value={prodPrecioUnitario}
                            name="prodPrecioUnitario"
                            error={error.prodPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodMarca"
                            label="Marca"
                            value={prodMarca}
                            name="prodMarca"
                            error={error.prodMarca}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "",+ */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodModelo"
                            label="Modelo"
                            value={prodModelo}
                            name="prodModelo"
                            error={error.prodModelo}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "40+", // SELECT => 36,38,40,42,44,46 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodTalla"
                            label="Talla"
                            value={prodTalla}
                            name="prodTalla"
                            error={error.prodTalla}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "0",
                              "36",
                              "37",
                              "38",
                              "39",
                              "40",
                              "41",
                              "42",
                              "43",
                              "44",
                              "46",
                              "48",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodColor"
                            label="Color"
                            value={prodColor}
                            name="prodColor"
                            error={error.prodColor}
                            onChange={(e) => handleChange(e)}
                          />{" "}
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            label="Condiciones"
                            name="condiciones"
                            value={condiciones}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setitem({
                                ...item,
                                condiciones: e.target.value,
                              });
                            }}
                            select
                            error={error.condiciones}
                            SelectProps={{
                              displayEmpty: true,
                              multiple: true,
                            }}
                          >
                            {[
                              "NUEVO",
                              "USADO",
                              "OPERABLE",
                              "BUEN ESTADO",
                              "DEFECTUOSO",
                            ].map((item) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField> */}
                          {prodTipo === "EQUIPOS" && (
                            <TextField
                              type="date"
                              className={clases.formD}
                              size="small"
                              label="FecCaducidad"
                              value={prodFecCaducidad}
                              name="prodFecCaducidad"
                              error={error.prodFecCaducidad}
                              onChange={(e) => handleChange(e)}
                            />
                          )}
                        </>
                      ) : null}
                    </>
                    <>
                      {prodTipo === "ARMAS" ? (
                        <>
                          {/* : "", */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodSerie"
                            label="Serie"
                            value={prodSerie}
                            name="prodSerie"
                            error={error.prodSerie}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  e.target.value +
                                  " - " +
                                  prodMarca +
                                  " - " +
                                  prodTipoArma +
                                  " - " +
                                  prodCalibre,
                              });
                            }}
                          />
                          {/* : "", */}
                          <TextField
                            disabled
                            className={clases.formD}
                            size="small"
                            id="prodNombre"
                            label="Nombre"
                            value={prodNombre}
                            name="prodNombre"
                            error={error.prodNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NUEVO", */}
                          {/* <TextField
            className={clases.formD}
            size="small"
            id="prodProductoEstado"
            label="Producto Estado"
            value={prodProductoEstado}
            name="prodProductoEstado"
            error={error.prodProductoEstado}
            onChange={(e) => handleChange(e)}
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
              (item) => {
                return (
                  <MenuItem value={item}>{item} </MenuItem>
                );
              }
            )}
          </TextField> */}
                          {/* : "", // TEXT */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="prodPrecioUnitario"
                            label="Precio Unitario"
                            value={prodPrecioUnitario}
                            name="prodPrecioUnitario"
                            error={error.prodPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodMarca"
                            label="Marca"
                            value={prodMarca}
                            name="prodMarca"
                            error={error.prodMarca}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  prodTipoArma +
                                  " - " +
                                  prodCalibre,
                              });
                            }}
                          />
                          {/* : "", // TEXT */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodModelo"
                            label="Modelo"
                            value={prodModelo}
                            name="prodModelo"
                            error={error.prodModelo}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "LETAL", // SELECT => LETAL,NO LETAL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodClase"
                            label="Clase"
                            value={prodClase}
                            name="prodClase"
                            error={error.prodClase}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["LETAL", "NO LETAL"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodTipoArma"
                            label="Tipo Arma"
                            value={prodTipoArma}
                            name="prodTipoArma"
                            error={error.prodTipoArma}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  prodMarca +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  prodCalibre,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "REVOLVER",
                              "PISTOLA",
                              "ESCOPETA",
                              "FUSIL",
                              "MUNICION",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodCalibre"
                            label="Calibre"
                            value={prodCalibre}
                            name="prodCalibre"
                            error={error.prodCalibre}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  prodMarca +
                                  " - " +
                                  prodTipoArma +
                                  " - " +
                                  e.target.value,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              ".38",
                              "9MM",
                              ".32/CAL 12",
                              "CAL 16",
                              "CAL 22",
                              "12GA",
                              "20GA",
                              "16GA",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "", // TEXT */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodColor"
                            label="Color"
                            value={prodColor}
                            name="prodColor"
                            error={error.prodColor}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "FIJA", // SELECT => FIJA,MOVIL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodUso"
                            label="Uso"
                            value={prodUso}
                            name="prodUso"
                            error={error.prodUso}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["FIJA", "MOVIL"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "APTA", // SELECT => APTA,DESCOMISADA,PERDIDA */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodStatus"
                            label="Status"
                            value={prodStatus}
                            name="prodStatus"
                            error={error.prodStatus}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              "APTA",
                              "DESCOMISADA",
                              "PERDIDA",
                              "MANTENIMIENTO",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            label="Condiciones"
                            name="condiciones"
                            value={condiciones}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setitem({
                                ...item,
                                condiciones: e.target.value,
                              });
                            }}
                            select
                            error={error.condiciones}
                            SelectProps={{
                              displayEmpty: true,
                              multiple: true,
                            }}
                          >
                            {[
                              "NUEVO",
                              "USADO",
                              "OPERABLE",
                              "BUEN ESTADO",
                              "DEFECTUOSO",
                            ].map((item) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField> */}
                          {/* prodFecValidacion: FechaExacta, // input date */}
                          <TextField
                            type="date"
                            className={clases.formD}
                            size="small"
                            label="FecValidacion"
                            value={prodFecValidacion}
                            name="prodFecValidacion"
                            error={error.prodFecValidacion}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* prodFecCaducidad: FechaExacta, // input date */}
                          <TextField
                            type="date"
                            className={clases.formD}
                            size="small"
                            label="FecCaducidad"
                            value={prodFecCaducidad}
                            name="prodFecCaducidad"
                            error={error.prodFecCaducidad}
                            onChange={(e) => handleChange(e)}
                          />{" "}
                        </>
                      ) : null}
                    </>
                    <>
                      {prodTipo === "MUNICIONES" ? (
                        <>
                          {/* : "", */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodSerie"
                            label="Serie"
                            value={prodSerie}
                            name="prodSerie"
                            error={error.prodSerie}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  e.target.value +
                                  " - " +
                                  prodCalibre +
                                  " - " +
                                  prodClase +
                                  " - " +
                                  item.prodMaterial,
                              });
                            }}
                          />
                          {/* : "", */}
                          <TextField
                            disabled
                            className={clases.formD}
                            size="small"
                            id="prodNombre"
                            label="Nombre"
                            value={prodNombre}
                            name="prodNombre"
                            error={error.prodNombre}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* : "NUEVO", */}
                          {/* <TextField
            className={clases.formD}
            size="small"
            id="prodProductoEstado"
            label="Producto Estado"
            value={prodProductoEstado}
            name="prodProductoEstado"
            error={error.prodProductoEstado}
            onChange={(e) => handleChange(e)}
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map(
              (item) => {
                return (
                  <MenuItem value={item}>{item} </MenuItem>
                );
              }
            )}
          </TextField> */}
                          {/* : "", // TEXT */}
                          <TextField
                            type="number"
                            className={clases.formD}
                            size="small"
                            id="prodPrecioUnitario"
                            label="Precio Unitario"
                            value={prodPrecioUnitario}
                            name="prodPrecioUnitario"
                            error={error.prodPrecioUnitario}
                            onChange={(e) => handleChange(e)}
                          />
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodMarca"
                            label="Marca"
                            value={prodMarca}
                            name="prodMarca"
                            error={error.prodMarca}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                          {/* : "", // TEXT */}
                          {/* : "LETAL", // SELECT => LETAL,NO LETAL */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodClase"
                            label="Clase"
                            value={prodClase}
                            name="prodClase"
                            error={error.prodClase}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  prodCalibre +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  item.prodMaterial,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["LETAL", "FOGUEO", "TRAUMATICA"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          {/* : "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL */}
                          {/* : "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22 */}
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodCalibre"
                            label="Calibre"
                            value={prodCalibre}
                            name="prodCalibre"
                            error={error.prodCalibre}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  e.target.value +
                                  " - " +
                                  prodClase +
                                  " - " +
                                  item.prodMaterial,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {[
                              ".38",
                              "9MM",
                              ".32",
                              "CAL 12",
                              "CAL 16",
                              "CAL 22",
                              "12GA",
                              "20GA",
                              "16GA",
                            ].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                          <TextField
                            className={clases.formD}
                            size="small"
                            id="prodMaterial"
                            label="Material"
                            value={item.prodMaterial}
                            name="prodMaterial"
                            error={error.prodMaterial}
                            onChange={(e) => {
                              setitem({
                                ...item,
                                [e.target.name]: e.target.value,
                                prodNombre:
                                  prodSerie +
                                  " - " +
                                  prodCalibre +
                                  " - " +
                                  prodClase +
                                  " - " +
                                  e.target.value,
                              });
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["PLOMO", "ACERO"].map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>{" "}
                          {/* <TextField
                            className={clases.formD}
                            size="small"
                            label="Condiciones"
                            name="condiciones"
                            value={condiciones}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setitem({
                                ...item,
                                condiciones: e.target.value,
                              });
                            }}
                            select
                            error={error.condiciones}
                            SelectProps={{
                              displayEmpty: true,
                              multiple: true,
                            }}
                          >
                            {[
                              "NUEVO",
                              "USADO",
                              "OPERABLE",
                              "BUEN ESTADO",
                              "DEFECTUOSO",
                            ].map((item) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField>{" "} */}
                          <TextField
                            type="date"
                            className={clases.formD}
                            size="small"
                            label="FecCaducidad"
                            value={prodFecCaducidad}
                            name="prodFecCaducidad"
                            error={error.prodFecCaducidad}
                            onChange={(e) => handleChange(e)}
                          />
                          {/* prodFecValidacion: FechaExacta, // input date */}
                          {/* : "", // TEXT */}
                        </>
                      ) : null}
                    </>
                    {editar && (
                      <TextField
                        disabled
                        type="number"
                        className={clases.formD}
                        size="small"
                        label="Stock"
                        value={item.stock_disponible}
                        name="stock_disponible"
                        error={error.stock_disponible}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                    <TextField
                      multiline
                      rows={3}
                      className={clases.formAllW}
                      variant="outlined"
                      size="small"
                      id="prodObservacion"
                      label="Observación"
                      value={prodObservacion}
                      name="prodObservacion"
                      error={error.prodObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {seccionActive === "DOCUMENTOS" ? (
                  <>
                    {prodTipo === "ARMAS" && (
                      <CampoDoc
                        setcargando={setcargando}
                        label="Permiso Pdf"
                        propiedad={"prodPermisoPdf"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                    )}

                    {/* <CampoDoc
                      setcargando={setcargando}
                      label="Comprobante Pdf"
                      propiedad={"prodComprobantePdf"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />*/}
                    <CampoDoc
                      setcargando={setcargando}
                      label="Documento"
                      propiedad={"prodDocumento"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    <Box width={"100%"} mt={0.25}>
                      <Dropzone
                        imagenes={imagenes}
                        setimagenes={(e) => {
                          setmodificoImagenes(true);
                          setimagenes(e);
                        }}
                        fotosItem={prodFotos}
                      />
                    </Box>
                  </>
                ) : null}
                {seccionActive === "COMPRAS" && (
                  <>
                    <Compras
                      desdeProductos
                      prodId={item.prodId}
                      producto={item}
                    />
                  </>
                )}
                {seccionActive === "STOCK" && (
                  <>
                    <Stocks desdeProductos producto={item} />
                  </>
                )}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
