import React, { memo, useContext } from "react";

import { Box, Collapse, Divider, TableCell, TableRow } from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  QrCode,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import clienteAxios from "../../../config/axios";
import { trimPropsItem } from "../../../config/const";
import CeldaUser from "../../components/CeldaUser";

import Stocks from "../../Configuración/Stocks/Stocks";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    itemQr,
    setitemQr,
    setopenQr,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Productos");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          nombre: usuario.nombre,
          tabla: "productos",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          prodUser: usuario.usuario + "/+/" + row[campoExport],
          prodEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "productos",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        prodUser: usuario.usuario,
        prodEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState({
      ...row,
    });
  };
  // ESCUCHA LOS CAMBIOS DEL REGISTRO

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== row.prodUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setitemQr({
                  ...itemQr,
                  prodId: row.prodId,
                  rucempresa: row.prodEmpresa,
                });
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/producto_individual?prodId=${row.prodId}&rucempresa=${usuario.rucempresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 4,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setexpandir(!expandir);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "primary",
              id: 4,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: () => {},
              disabled: false,
              Icon: "",
              color: "success",
              id: 5,
              ocultar: row.eliminado,
              tipo: "check",
              estado:
                row.prodEstado === "ACTIVO" || row.prodEstado === "ACTIVA",
              variante: "",
              size: "medium",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          {row.prodTipo === "VESTIMENTAS" || row.prodTipo === "EQUIPOS" ? (
            <>
              {" "}
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTipo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodModelo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.stock_disponible}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTalla}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.prodFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>
              <CeldaUser user={row.prodUser} claseCelda={claseCelda} />
            </>
          ) : null}
          {row.prodTipo === "ARMAS" ? (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodModelo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.stock_disponible}
              </TableCell>{" "}
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTipoArma}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.prodFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>
              <CeldaUser user={row.prodUser} claseCelda={claseCelda} />
            </>
          ) : null}
          {row.prodTipo === "MUNICIONES" ? (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.stock_disponible}
              </TableCell>

              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.prodFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>

              <CeldaUser user={row.prodUser} claseCelda={claseCelda} />
            </>
          ) : null}
        </>
      </TableRow>
      <TableRow>
        <TableCell sx={{ margin: 0, padding: 0, pl: 3 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box mt={0.5}>
              <Divider textAlign="left">STOCK</Divider>
              <Stocks
                socketProp={socket}
                desdeProductos={true}
                ocultarAcciones
                prodId={row.prodId}
                producto={{
                  prodId: row.prodId,
                  prodNombre: row.prodNombre,
                  prodTipo: row.prodTipo,
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
