import React, { useState, useContext, useEffect, memo, createRef } from "react";

import { CompactPicker } from "react-color";
import Draggable from "react-draggable";

// eslint-disable-next-line
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import {
  validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  InputAdornment,
  Avatar,
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Paper,
  TableHead,
  TableBody,
  TableContainer,
  Table,
} from "@mui/material";
import {
  Cancel,
  Visibility,
  VisibilityOff,
  CloudUpload,
  Close,
  Download,
  DeleteForever,
  AddCircle,
} from "@mui/icons-material";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import RowClave from "./Clave/RowClave";
import RowAgregarClave from "./Clave/RowAgregarClave";
import { WhatsappConexion } from "./WhatsappConexion";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formDDoc: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "35%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "35%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "70%",
    },
  },
  formExp: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    marginLeft: "0px",
    marginRight: theme.spacing(1),
    width: "12%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "12%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "25%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [empresa, setempresa] = useState(ObjectoActivo);
  const [verpass, setverpass] = useState(false);
  const [seccionActive, setseccionActive] = useState({
    datos: true,
    archivos: false,
    notificacion: false,
    claves: false,
    whatsapp: false,
  });
  const [archivo, setarchivo] = useState("");
  const [imagenMostrada, setimagenMostrada] = useState(null);
  const [firma, setfirma] = useState("");
  const [cargando, setcargando] = useState(false);
  const [colorPicker, setcolorPicker] = useState(false);
  const inputEl = createRef();

  const focusInput = () => {
    inputEl.current.click();
  };
  const onChangeFile = (e) => {
    if (!e.target.files[0]) {
      return;
    }

    setarchivo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setimagenMostrada(e.target.result);
    };
  };
  const onChangeFirma = (e) => {
    if (!e.target.files[0]) {
      return setfirma("");
    } else {
      setfirma(e.target.files[0]);
    }
  };
  const { datos, archivos, notificacion, claves, whatsapp } = seccionActive;
  const {
    empRuc,
    empNombre,
    empRepresentante,
    empComercial,
    empObligado,
    empDireccion,
    empTelefono,
    empTipo,
    // empEmail,
    empIva,
    empIce,
    empEstado,
    // empLogo,
    empFirma,
    empClavef,
    empCodigo,
    empFechaCaducaFirma,
    empRegimen,
    empTipografia,
    empTerminosCarnet,
    empDocumentos,
    empCategoria,
    empColorPrimario,
    empColorSecundario,
    empClaves,
    empColorDrawer,
    empLeyenda,
    empContribuyenteEspecial,
    empAgenteRetencion,
  } = empresa;
  const [error, seterror] = useState({
    empRuc: false,
    empNombre: false,
    empRepresentante: false,
    empComercial: false,
    empObligado: false,
    empDireccion: false,
    empTelefono: false,
    empTipo: false,
    empEmail: false,
    empIva: false,
    empIce: false,
    empEstado: false,
    empLogo: false,
    empFirma: false,
    empClavef: false,
    empCodigo: false,
  });
  const [documentosArray, setdocumentosArray] = useState([]);
  const [documentosObject, setdocumentosObject] = useState({});
  const [objetoReferencias, setobjetoReferencias] = useState({});
  const focusInputDocumento = (itemPropiedad) => {
    objetoReferencias[itemPropiedad.propiedad].current.click();
  };
  const onChangeDocumento = async (e, itempropiedad) => {
    if (!e.target.files[0]) {
      return;
    }
    if (itempropiedad.extension === "PDF") {
      if (
        !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta("El archivo debe ser PDF", "error");
      }
    }
    if (itempropiedad.extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];

      if (empDocumentos[itempropiedad.propiedad] === "") {
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        arrayEliminar.push(empDocumentos[itempropiedad.propiedad]);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      formDataLogos.append("carpeta", "documentos");

      const resDocumentos = await clienteAxios.post(
        "/subir_eliminar_archivo/subir",
        formDataLogos
      );

      const resEliminados = await clienteAxios.post(
        "/subir_eliminar_archivo/eliminar",
        { urls: arrayEliminar.filter((itemE) => itemE !== "") }
      );

      setempresa({
        ...empresa,
        empDocumentos: {
          ...empresa.empDocumentos,
          [itempropiedad.propiedad]: resDocumentos.data.url,
        },
      });

      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: e.target.files[0],
      });
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: documentosObject[itempropiedad.propiedad],
      });

      return mostrarAlerta("Hubo un error", "error");
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await clienteAxios.get(
          "/documentos/filtro/0?perfil=EMPRESA"
        );

        const itemRes = res.data.data[0];
        // itemRes.docDocumentos;
        setdocumentosArray(itemRes.docDocumentos);

        if (editar) {
          const empDocumentos = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            if (!ObjectoActivo.empDocumentos[itemDocumento.propiedad]) {
              empDocumentos[itemDocumento.propiedad] = "";
              empDocumentos[itemDocumento.propiedad + "expira"] = "";
            }
          });

          //DOCUMENTOS OBJECT
          const documentosObject = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            documentosObject[itemDocumento.propiedad] = "";
          });

          //DOCUMENTOS OBJECT
          const objetoReferencias = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            objetoReferencias[itemDocumento.propiedad] = createRef();
          });

          setobjetoReferencias(objetoReferencias);
          setdocumentosObject(documentosObject);

          setempresa({
            ...ObjectoActivo,
            empDocumentos: { ...ObjectoActivo.empDocumentos, ...empDocumentos },
          });
        } else {
          const empDocumentos = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            empDocumentos[itemDocumento.propiedad] = "";
            empDocumentos[itemDocumento.propiedad + "expira"] = "";
          });

          //DOCUMENTOS OBJECT
          const documentosObject = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            documentosObject[itemDocumento.propiedad] = "";
          });

          //DOCUMENTOS OBJECT
          const objetoReferencias = {};
          itemRes.docDocumentos.forEach((itemDocumento) => {
            objetoReferencias[itemDocumento.propiedad] = createRef();
          });

          setobjetoReferencias(objetoReferencias);
          setdocumentosObject(documentosObject);

          setempresa({
            ...ObjectoActivo,
            empDocumentos,
            // perDocTipo: clienteProveedor === "USUARIOS" ? "PASAPORTE" : "RUC",
            // perOtros: ObjectoActivo.perOtros ? ObjectoActivo.perOtros : [],
          });
        }
      } catch (error) {
        console.log(error);
        mostrarAlerta(
          "NO HAY ARCHIVOS DINÁMICOS, REVISAR LA CONFIGURACIÓN DE DOCUMENTOS",
          "error"
        );
        setempresa({
          ...ObjectoActivo,
        });
      }
    };
    getData();

    // eslint-disable-next-line
  }, [ObjectoActivo]);
  const {
    agregandoClave,
    setagregandoClave,
    pageClave,
    // setPageClave,
    rowsPerPageClave,
    // setRowsPerPageClave,
    handleChangePageClave,
    handleChangeRowsPerPageClave,
  } = useSimpleTable("Clave");

  const defaultActive = () => {
    changeObjetoActivoState({
      empTerminosCarnet: "",
      empTipografia: "",
      empRuc: "",
      empNombre: "",
      empRepresentante: "",
      empComercial: "",
      empObligado: "",
      empDireccion: "",
      empTelefono: "",
      empTipo: "",
      empIva: "",
      empIce: "",
      empEstado: "ACTIVO",
      empLogo: "",
      empFirma: "",
      empClavef: "",
      empCodigo: "",
      empRegimen: "",
      empFechaCaducaFirma: "",
      correoEnv: "",
      correoResp: "",
      empCopiaEmail: "",
      empPaginaWeb: "",
      empFacebook: "",
      empInstagram: "",
      empColor: "",
      empUser: "",
      empFecReg: "",
      Todos: "",
      empDocumentos: {},
      empCategoria: "START",
      empColorPrimario: "#2196f3",
      empColorSecundario: "#263238",
      empColorDrawer: "#ffffff",
      empClaves: [],
      empLeyenda: "",
      empContribuyenteEspecial: "",
      empAgenteRetencion: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      empRuc: false,
      empNombre: false,
      empRepresentante: false,
      empComercial: false,
      empObligado: false,
      empDireccion: false,
      empTelefono: false,
      empTipo: false,

      empIva: false,
      empIce: false,
      empEstado: false,
      empLogo: false,
      empFirma: false,
      empClavef: false,
      empCodigo: false,
      empFechaCaducaFirma: false,
      empRegimen: false,
    });
    setseccionActive({
      datos: true,
      archivos: false,
      notificacion: false,
      claves: false,
      whatsapp: false,
    });
    setarchivo("");
    setimagenMostrada(null);
    setfirma("");
    setcargando(false);
    setdocumentosArray([]);
    setdocumentosObject({});
    setobjetoReferencias({});
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setempresa({
      ...empresa,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const validarDocumentosArray = () => {
    const obligatorios = documentosArray.filter(
      (documentoItem) => documentoItem.obligatorio === "SI"
    );
    obligatorios.map((obligatorioItem) => {
      if (empDocumentos[obligatorioItem.propiedad] === "") {
        return {
          ...obligatorioItem,
          error: true,
        };
      } else {
        return {
          ...obligatorioItem,
          error: false,
        };
      }
    });

    const existeSinLlenar = obligatorios.some((obligatorioItem) => {
      return empDocumentos[obligatorioItem.propiedad] === "";
    });
    if (existeSinLlenar) {
      const llenos = documentosArray.map((obligatorioItem) => {
        if (
          empDocumentos[obligatorioItem.propiedad] === "" &&
          obligatorioItem.obligatorio === "SI"
        ) {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });

      setcargando(false);
      setdocumentosArray(llenos);
      return true;
    } else {
      const llenos = documentosArray.map((obligatorioItem) => {
        return {
          ...obligatorioItem,
          error: false,
        };
      });

      setdocumentosArray(llenos);
      return false;
    }
  };
  const validacion = () => {
    if (
      empRuc.trim() === "" ||
      empNombre.trim() === "" ||
      empRepresentante.trim() === "" ||
      empComercial.trim() === "" ||
      empObligado.trim() === "" ||
      empDireccion.trim() === "" ||
      empTelefono.trim() === "" ||
      empTipo === "" ||
      empIva === "" ||
      empIce === "" ||
      empEstado.trim() === "" ||
      empClavef.trim() === "" ||
      empCodigo.trim() === "" ||
      empFechaCaducaFirma.trim() === "" ||
      empresa.correoEnv.trim() === "" ||
      validarEmail(empresa.correoEnv) ||
      empresa.correoResp.trim() === "" ||
      validarEmail(empresa.correoResp) ||
      empresa.empColor.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      seterror({
        empRuc: empRuc.trim() === "" ? true : false,
        empNombre: empNombre.trim() === "" ? true : false,
        empRepresentante: empRepresentante.trim() === "" ? true : false,
        empComercial: empComercial.trim() === "" ? true : false,
        empObligado: empObligado.trim() === "" ? true : false,
        empDireccion: empDireccion.trim() === "" ? true : false,
        empTelefono: empTelefono.trim() === "" ? true : false,
        empTipo: empTipo === "" ? true : false,
        empIva: empIva === "" ? true : false,
        empIce: empIce === "" ? true : false,
        empEstado: empEstado.trim() === "" ? true : false,
        empClavef: empClavef.trim() === "" ? true : false,
        empCodigo: empCodigo.trim() === "" ? true : false,
        empFechaCaducaFirma: empFechaCaducaFirma.trim() === "" ? true : false,
        correoEnv:
          empresa.correoEnv.trim() === "" || validarEmail(empresa.correoEnv)
            ? true
            : false,
        correoResp:
          empresa.correoResp.trim() === "" || validarEmail(empresa.correoResp)
            ? true
            : false,
        empColor: empresa.empColor.trim() === "" ? true : false,
      });
      return true;
    }

    if (validarDocumentosArray()) {
      return true;
    }
  };
  const handleCrear = async () => {
    try {
      await validarDocumento(empRuc);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        empRuc: true,
      });
    }
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios por llenar", "error");
    }
    try {
      const resFirma = ["x-pkcs12"].some(
        (item) => item === firma.type.split("/")[1]
      );
      if (!resFirma) {
        return mostrarAlerta(
          "Comprueba la extensión de tus firma, recuerda que los formatos aceptados son p12",
          "error"
        );
      }

      setcargando(true);

      // Logo

      let resLogos = "";

      if (archivo !== "") {
        const formDataLogos = new FormData();
        let arrayEliminar = [empresa.empLogo];

        await formDataLogos.append("archivo", archivo);
        formDataLogos.append("carpeta", "logos");

        const resDocumentos = await clienteAxios.post(
          "/subir_eliminar_archivo/subir",
          formDataLogos
        );

        await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
          urls: arrayEliminar.filter((itemE) => itemE !== ""),
        });
        resLogos = resDocumentos.data.url;
      }
      let resFirmas = "";
      if (firma !== "") {
        const formDataFirmas = new FormData();
        let arrayEliminar = [empresa.empFirma];

        await formDataFirmas.append("archivo", firma);
        formDataFirmas.append("carpeta", "logos");

        const resDocumentos = await clienteAxios.post(
          "subir_firma_electronica/upload",
          formDataFirmas
        );

        // await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        //   urls: arrayEliminar.filter((itemE) => itemE !== ""),
        // });

        // resFirmas = resDocumentos.data.url;
      }

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...empresa,
          tabla: "empresa",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          empUser: usuario.usuario,
          empEmpresa: usuario.rucempresa,
          empLogo: archivo === "" ? empresa.empLogo : resLogos,
          empFirma: firma === "" ? empresa.empFirma : resFirmas,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });

      setcargando(false);
      mostrarAlerta("Selecciona una foto o una firma", "error");
    }
  };
  const handleEditar = async () => {
    try {
      await validarDocumento(empRuc);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        empRuc: true,
      });
    }
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios por llenar", "error");
    }
    try {
      let resFirma = true;
      if (firma.type) {
        resFirma = ["x-pkcs12"].some(
          (item) => item === firma.type.split("/")[1]
        );
      }
      if (!resFirma) {
        return mostrarAlerta(
          "Comprueba la extensión de tus firma, recuerda que los formatos aceptados son p12",
          "error"
        );
      }

      setcargando(true);
      // Logo

      let resLogos = "";

      if (archivo !== "") {
        const formDataLogos = new FormData();
        let arrayEliminar = [empresa.empLogo];

        await formDataLogos.append("archivo", archivo);
        formDataLogos.append("carpeta", "logos");

        const resDocumentos = await clienteAxios.post(
          "/subir_eliminar_archivo/subir",
          formDataLogos
        );

        await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
          urls: arrayEliminar.filter((itemE) => itemE !== ""),
        });
        resLogos = resDocumentos.data.url;
      }
      let resFirmas = "";

      if (firma !== "") {
        const formDataFirmas = new FormData();
        // let arrayEliminar = [empresa.empFirma];

        await formDataFirmas.append("archivo", firma);
        formDataFirmas.append("carpeta", "logos");

        const resDocumentos = await clienteAxios.post(
          "subir_firma_electronica/upload",
          formDataFirmas
        );

        // await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        //   urls: arrayEliminar.filter((itemE) => itemE !== ""),
        // });

        resFirmas = resDocumentos.data.url;
      }

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...empresa,
          tabla: "empresa",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          empUser: usuario.usuario,
          empEmpresa: usuario.rucempresa,
          empLogo: archivo === "" ? empresa.empLogo : resLogos,
          empFirma: firma === "" ? empresa.empFirma : resFirmas,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
    }
  };
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} empresa.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ datos: true })}
                  >
                    Datos principales
                  </Button>
                  <Button
                    variant={notificacion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ notificacion: true })}
                  >
                    Notificación
                  </Button>
                  <Button
                    variant={archivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ archivos: true })}
                  >
                    Archivos
                  </Button>
                  {usuario.rol.includes("ADMIN") && (
                    <Button
                      variant={claves ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ claves: true })}
                    >
                      Claves
                    </Button>
                  )}
                  <Button
                    variant={whatsapp ? "contained" : "outlined"}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={"success"}
                    onClick={() => setseccionActive({ whatsapp: true })}
                  >
                    whatsapp
                  </Button>
                </Box>

                {editar ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={empEstado === "ACTIVO" ? true : false}
                        onChange={(e) => {
                          setempresa({
                            ...empresa,
                            empEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                          });
                        }}
                        name="antoine"
                      />
                    }
                    label="Estado"
                    color="primary"
                  />
                ) : null}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                {datos ? (
                  <>
                    <TextField
                      type="number"
                      onBlur={async (e) => {
                        try {
                          await validarDocumento(e.target.value);

                          mostrarAlerta("Identificación válida", "success");
                        } catch (error) {
                          mostrarAlerta(error, "error");
                        }
                      }}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRuc"
                      disabled={editar}
                      label="Ruc"
                      value={empRuc}
                      name="empRuc"
                      error={error.empRuc}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      disabled={editar}
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empCodigo"
                      label="Código Empresa"
                      value={empCodigo}
                      name="empCodigo"
                      error={error.empCodigo}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empNombre"
                      label="Nombre"
                      value={empNombre}
                      name="empNombre"
                      error={error.empNombre}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRepresentante"
                      label="Representante"
                      value={empRepresentante}
                      name="empRepresentante"
                      error={error.empRepresentante}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empComercial"
                      label="Nombre Comercial"
                      value={empComercial}
                      name="empComercial"
                      error={error.empComercial}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empObligado"
                      label="Obligado"
                      value={empObligado}
                      name="empObligado"
                      error={error.empObligado}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["SI", "NO"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empDireccion"
                      label="Dirección"
                      value={empDireccion}
                      name="empDireccion"
                      error={error.empDireccion}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empTelefono"
                      label="Teléfono"
                      value={empTelefono}
                      name="empTelefono"
                      error={error.empTelefono}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empTipo"
                      label="Tipo"
                      value={empTipo}
                      name="empTipo"
                      type="number"
                      error={error.empTipo}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    {/* <TextField
                    fullWidth
                    id="empEmail"
                    label="Email"
                    value={empEmail}
                    name="empEmail"
                    error={error.empEmail}
                    onChange={(e) => handleChange(e)}
                  /> */}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empIva"
                      label="Iva"
                      value={empIva}
                      name="empIva"
                      type="number"
                      error={error.empIva}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empIce"
                      label="Ice"
                      value={empIce}
                      name="empIce"
                      type="number"
                      error={error.empIce}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.valueAsNumber,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{
                        className: clases.inputPadding,
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      id="empFechaCaducaFirma"
                      name="empFechaCaducaFirma"
                      InputLabelProps={{ shrink: true }}
                      value={empFechaCaducaFirma.substr(0, 10)}
                      label="F.Caducidad Firma"
                      type="date"
                      error={error.empFechaCaducaFirma}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empRegimen"
                      name="empRegimen"
                      value={empRegimen}
                      label="Régimen"
                      error={error.empRegimen}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empCategoria"
                      label="Categoria"
                      value={empCategoria}
                      name="empCategoria"
                      error={error.empCategoria}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>
                      {["START", "PLUS"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </TextField>{" "}
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empLeyenda"
                      name="empLeyenda"
                      value={empLeyenda}
                      label="Leyenda"
                      error={error.empLeyenda}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Contribuyente Especial"
                      name="empContribuyenteEspecial"
                      value={empContribuyenteEspecial}
                      error={error.empContribuyenteEspecial}
                      onChange={(e) => {
                        setempresa({
                          ...empresa,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["PERSONA NATURAL", "PERSONA JURIDICA", "SOCIEDAD"].map(
                        (item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        }
                      )}
                    </TextField>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      label="Agente Retención"
                      name="empAgenteRetencion"
                      value={empAgenteRetencion}
                      error={error.empAgenteRetencion}
                      onChange={(e) => handleChange(e)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="">Seleccione </MenuItem>;
                      {["SI", "NO"].map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Color Primario"
                      value={empColorSecundario}
                      name="empColorSecundario"
                      error={error.empColorSecundario}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Color Secundario"
                      value={empColorPrimario}
                      name="empColorPrimario"
                      error={error.empColorPrimario}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      type="color"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formAllW}
                      label="Color de Fondo Sidebar"
                      value={empColorDrawer}
                      name="empColorDrawer"
                      error={error.empColorDrawer}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant={"outlined"}
                      className={clases.formAllW}
                      id="empTerminosCarnet"
                      name="empTerminosCarnet"
                      value={empTerminosCarnet}
                      label="Terminos Carnet"
                      error={error.empTerminosCarnet}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </>
                ) : null}
                {notificacion ? (
                  <>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="correoEnv"
                      label="Email de Envio"
                      value={empresa.correoEnv}
                      name="correoEnv"
                      error={error.correoEnv}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="correoResp"
                      label="Correo de Respuesta"
                      value={empresa.correoResp}
                      name="correoResp"
                      error={error.correoResp}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                        >
                          Recibir copia de email
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={empresa.empCopiaEmail}
                          name="empCopiaEmail"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empPaginaWeb"
                      label="Pagina"
                      value={empresa.empPaginaWeb}
                      name="empPaginaWeb"
                      error={error.empPaginaWeb}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empFacebook"
                      label="Facebook"
                      value={empresa.empFacebook}
                      name="empFacebook"
                      error={error.empFacebook}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empInstagram"
                      label="Instagram"
                      value={empresa.empInstagram}
                      name="empInstagram"
                      error={error.empInstagram}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empTipografia"
                      label="Tipografía"
                      value={empTipografia}
                      name="empTipografia"
                      error={error.empTipografia}
                      onChange={(e) => handleChange(e)}
                    />
                    {colorPicker ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        className={clases.margin}
                      >
                        <CompactPicker
                          name="empColor"
                          className={clases.margin}
                          color={empresa.empColor}
                          onChange={(e) => {
                            setempresa({
                              ...empresa,
                              empColor: e.hex,
                            });
                          }}
                        ></CompactPicker>
                        <IconButton
                          aria-label=""
                          onClick={() => setcolorPicker(!colorPicker)}
                        >
                          <Close color="error" />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        className={clases.margin}
                      >
                        <Button
                          size="large"
                          variant="outlined"
                          style={{ backgroundColor: empresa.empColor }}
                          onClick={() => setcolorPicker(!colorPicker)}
                        >
                          Color
                        </Button>
                      </Box>
                    )}
                  </>
                ) : null}
                {archivos ? (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <input
                        id="contained-button-file"
                        className={clases.ocultar}
                        type="file"
                        name="myImage"
                        onChange={onChangeFile}
                        accept=".jpeg, .jpg, .png"
                      />

                      {/* //si un archivo exoste  */}
                      {archivo ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-file">
                            <Avatar
                              htmlFor="contained-button-file"
                              alt="Remy Sharp"
                              src={imagenMostrada}
                              className={clases.large}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            {archivo.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="contained-button-file">
                            <Avatar
                              htmlFor="contained-button-file"
                              alt="Logo"
                              src={empresa.empLogo}
                              className={clases.large}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            No se ha seleccionado ningun archivo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <input
                      className={clases.ocultar}
                      type="file"
                      name="myImage"
                      onChange={onChangeFirma}
                      ref={inputEl}
                    />
                    <label
                      htmlFor="contained-button-firma"
                      className={clases.formD}
                    >
                      <Box htmlFor="contained-button-firma" fullWidth>
                        <TextField
                          error={error.empFirma}
                          inputProps={{
                            className: clases.inputPadding,
                          }}
                          InputLabelProps={{
                            shrink:
                              firma !== "" || empFirma !== "" ? true : false,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton aria-label="" onClick={focusInput}>
                                  <CloudUpload color="secondary" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          id="empFirma"
                          label={`Firma`}
                          value={
                            empFirma && !firma.name
                              ? empFirma.split("/")[
                                  empFirma.split("/").length - 1
                                ]
                              : firma.name
                          }
                          helperText={
                            empresa.empFirma === "" && !editar ? null : (
                              <Typography
                                variant="body1"
                                color="primary"
                                component="a"
                                onClick={async () => {
                                  window.open(empresa.empFirma);
                                }}
                              >
                                Descarga Firma
                              </Typography>
                            )
                          }
                          name="empFirma"
                        />
                      </Box>
                    </label>
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      id="empClavef"
                      label="Clave"
                      value={empClavef}
                      name="empClavef"
                      error={error.empClavef}
                      onChange={(e) => handleChange(e)}
                      type={verpass ? "text" : "password"}
                      helperText={
                        editar ? (
                          <Typography
                            variant="body1"
                            color="primary"
                            onClick={async () => {}}
                          >
                            Clave
                          </Typography>
                        ) : null
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            {verpass ? (
                              <IconButton
                                aria-label=""
                                onClick={() => setverpass(!verpass)}
                              >
                                <Visibility color="secondary" />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label=""
                                onClick={() => setverpass(!verpass)}
                              >
                                <VisibilityOff color="secondary" />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* DOCUMENTOS ARRAY */}
                    {documentosArray.map(
                      (documentoItem, index, arraydocumentoItem) => {
                        // extension: "PDF"
                        // fecExpira: "NO"
                        // nombre: "Fotos Instalacion"
                        // obligatorio: "SI"
                        // propiedad: "fotosinstalacion"
                        return (
                          <Box
                            key={index}
                            display="flex"
                            flexWrap="wrap"
                            alignItems="flex-end"
                            borderRadius="0px 50px 50px 0px"
                            p={1}
                            className={clases.formD}
                          >
                            <input
                              className={clases.ocultar}
                              type="file"
                              name="myImage"
                              onChange={(e) => {
                                onChangeDocumento(e, documentoItem);
                              }}
                              ref={objetoReferencias[documentoItem.propiedad]}
                            />
                            <TextField
                              size="small"
                              className={clases.formDDoc}
                              error={documentoItem.error}
                              inputProps={{
                                className: clases.inputPadding,
                              }}
                              InputLabelProps={{
                                shrink:
                                  documentosObject[documentoItem.propiedad] !==
                                    "" ||
                                  (empresa.empDocumentos[
                                    documentoItem.propiedad
                                  ] !== "" &&
                                    empresa.empDocumentos[
                                      documentoItem.propiedad
                                    ])
                                    ? true
                                    : false,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    htmlFor="contained-button-documento"
                                    fullWidth
                                  >
                                    <InputAdornment>
                                      <Tooltip
                                        title={`Subir Archivo: ${documentoItem.nombre}`}
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            onClick={() =>
                                              focusInputDocumento(documentoItem)
                                            }
                                            aria-label=""
                                            size="small"
                                          >
                                            <CloudUpload
                                              color="secondary"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                      {empresa.empDocumentos[
                                        documentoItem.propiedad
                                      ] === "" ? null : (
                                        <IconButton
                                          color="primary"
                                          onClick={async () => {
                                            try {
                                              setdocumentosObject({
                                                ...documentosObject,
                                                [documentoItem.propiedad]: "",
                                              });
                                              setempresa({
                                                ...empresa,
                                                empDocumentos: {
                                                  ...empDocumentos,
                                                  [documentoItem.propiedad]: "",
                                                },
                                              });
                                              await clienteAxios.post(
                                                "/subir_eliminar_archivo/eliminar",
                                                {
                                                  urls: [
                                                    empresa.empDocumentos[
                                                      documentoItem.propiedad
                                                    ],
                                                  ],
                                                }
                                              );
                                            } catch (error) {}
                                          }}
                                        >
                                          <DeleteForever color="error" />
                                        </IconButton>
                                      )}
                                      {empresa.empDocumentos[
                                        documentoItem.propiedad
                                      ] === "" ? null : (
                                        <Tooltip
                                          title={`Descargar Archivo: ${documentoItem.nombre}`}
                                          arrow
                                        >
                                          <Box>
                                            <IconButton
                                              color="primary"
                                              onClick={async () => {
                                                window.open(
                                                  empresa.empDocumentos[
                                                    documentoItem.propiedad
                                                  ]
                                                );
                                              }}
                                              aria-label=""
                                            >
                                              <Download />
                                            </IconButton>
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </InputAdornment>
                                  </Box>
                                ),
                              }}
                              fullWidth
                              id={documentoItem.propiedad}
                              label={`${documentoItem.nombre}`}
                              value={
                                empresa.empDocumentos[
                                  documentoItem.propiedad
                                ] &&
                                !documentosObject[documentoItem.propiedad].name
                                  ? empresa.empDocumentos[
                                      documentoItem.propiedad
                                    ].split("/")[
                                      empresa.empDocumentos[
                                        documentoItem.propiedad
                                      ].split("/").length - 1
                                    ]
                                  : documentosObject[documentoItem.propiedad]
                                      .name
                                  ? documentosObject[documentoItem.propiedad]
                                      .name
                                  : ""
                              }
                              name={documentoItem.propiedad}
                            />

                            {documentoItem.fecExpira === "SI" ? (
                              <TextField
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  className: clases.inputPadding,
                                  min: FechaExacta,
                                }}
                                className={clases.formExp}
                                size="small"
                                id="perFecExpdatospersonales"
                                label="Expira"
                                value={
                                  empresa.empDocumentos[
                                    documentoItem.propiedad + "expira"
                                  ]
                                }
                                name="perFecExpdatospersonales"
                                error={error.perFecExpdatospersonales}
                                onChange={(e) =>
                                  setempresa({
                                    ...empresa,
                                    empDocumentos: {
                                      ...empDocumentos,
                                      [documentoItem.propiedad + "expira"]: e
                                        .target.value,
                                    },
                                  })
                                }
                              />
                            ) : null}
                          </Box>
                        );
                      }
                    )}
                  </>
                ) : null}
                {claves && (
                  <Paper sx={{ width: "100%" }}>
                    <ToolBarTable
                      count={empClaves.length}
                      rowsPerPage={rowsPerPageClave}
                      page={pageClave}
                      handleChangePage={handleChangePageClave}
                      handleChangeRowsPerPage={handleChangeRowsPerPageClave}
                      ocultarPaginacion={false}
                      simplePagination={true}
                      botonesIzquierdos={[
                        {
                          tooltip: "Agregar",
                          texto: "Agregar",
                          funcion: () => {
                            setagregandoClave(true);
                          },
                          disabled: false,
                          Icon: AddCircle,
                          color: "primary",
                          id: 1,
                          ocultar: false,
                          tipo: "boton",
                          variante: "contained",
                          size: "medium",
                          sx: {},
                        },
                      ]}
                      botonesDerechos={[]}
                    />

                    <TableContainer className={clases.container}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                      >
                        <TableHead>
                          <TablaCabecera
                            columns={[
                              {
                                id: "institucion",
                                align: "institucion",
                                minWidth: 125,
                                tipo: "string",
                                label: "institución",
                              },
                              {
                                id: "usuario",
                                align: "usuario",
                                minWidth: 125,
                                tipo: "string",
                                label: "usuario",
                              },
                              {
                                id: "clave",
                                align: "clave",
                                minWidth: 125,
                                tipo: "string",
                                label: "clave",
                              },
                              {
                                id: "observacion",
                                align: "observacion",
                                minWidth: 125,
                                tipo: "string",
                                label: "observación",
                              },
                            ]}
                            habilitarOrdenar={false}
                          />
                        </TableHead>

                        <TableBody component={"div"}>
                          {agregandoClave ? (
                            <RowAgregarClave
                              setagregar={setagregandoClave}
                              setitemPadre={setempresa}
                              itemPadre={empresa}
                            />
                          ) : null}
                          {empClaves
                            .slice(
                              pageClave * rowsPerPageClave,
                              pageClave * rowsPerPageClave + rowsPerPageClave
                            )
                            .map((itemHijo, index) => {
                              return (
                                <RowClave
                                  setitemPadre={setempresa}
                                  itemPadre={empresa}
                                  itemHijo={itemHijo}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}{" "}
                {whatsapp && (
                  <WhatsappConexion setseccionActive={setseccionActive} />
                )}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
