import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";
import MuiImageSlider from "mui-image-slider";
// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  MenuItem,
  Dialog,
  Box,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Photo,
  Visibility,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";
import { DropzoneDialog } from "react-mui-dropzone";
import Cantidades from "../Cantidades/Cantidades";
import { uploadImagesHelper } from "../../../functions/uploads/subirImagenes";

const RowArma = (props) => {
  const classes = useRowStyles();
  const {
    bodegaData,
    ver,
    item,
    setitem,
    row,
    index,
    devolucion,
    vestimenta,
    armas,
    municion,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const [cargado, setcargado] = useState(false);
  useEffect(() => {
    if (cargado) {
      return funcSubmit();
    }
    setcargado(true);
    //eslint-disable-next-line
  }, [itemHijoEdit.cantidades]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [imagenes, setimagenes] = useState([]);
  const [Openimagen, setOpenimagen] = useState(false);
  const [cargando, setcargando] = useState(false);

  const [open, setOpen] = useState(false);
  const [productosData, setproductosData] = useState([]);
  const [expandido, setexpandido] = useState(false);
  const PopperMy = function(props) {
    return <Popper {...props} placement="bottom-start" />;
  };

  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      distri_productos: item.distri_productos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };

  const [editar, seteditar] = useState(false);

  const botonDisabled = () => {
    if (
      itemHijoEdit.nombre === "" ||
      itemHijoEdit.marca === "" ||
      itemHijoEdit.modelo === "" ||
      itemHijoEdit.talla === "" ||
      itemHijoEdit.color === ""
      // ||
      // itemHijoEdit.cantidades.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    const existe = item.distri_productos.some(
      (productoS) =>
        productoS.prodId === itemHijoEdit.prodId &&
        itemHijoEdit.prodId !== row.prodId
    );
    if (existe) {
      return mostrarAlerta("Este producto ya se encuentra registrado", "error");
    }
    setcargando(true);
    try {
      let resDocumentos = {
        data: {
          urls: itemHijoEdit.fotos,
        },
      };

      if (imagenes.length > 0) {
        resDocumentos = await uploadImagesHelper(imagenes, itemHijoEdit.fotos);
      }
      setitem({
        ...item,
        distri_productos: item.distri_productos.map((item, itemIndex) => {
          if (index === itemIndex) {
            return { ...itemHijoEdit, fotos: resDocumentos.data.urls };
          } else {
            return item;
          }
        }),
      });
      setimagenes([]);
      setcargando(false);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
      seteditar(false);
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  useEffect(() => {
    if (editar) {
      const getData = async () => {
        try {
          const res = await clienteAxios.get(
            `/productos/search/para/distribucion?tipo_producto=${itemHijoEdit.prodTipo}&id_bodega=${itemHijoEdit.idBodega}&search=${itemHijoEdit.prodNombre}`
          );

          setproductosData(res.data);
        } catch (error) {
          return mostrarAlerta("No hay clientes", "error");
        }
      };
      getData();
    } // eslint-disable-next-line
  }, [editar]);

  return (
    <React.Fragment>
      <DropzoneDialog
        dialogProps={{ maxWidth: "lg" }}
        dialogTitle={
          <Typography variant="h5" color="primary">
            Subir archivos
          </Typography>
        }
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              Arrastra una imagen o da click aqui.
            </Typography>
            {editar ? (
              <Typography variant="h5" color="error">
                Subir nuevas imagenes eliminará las anteriores.
              </Typography>
            ) : null}
          </>
        }
        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "image/jpg"]}
        cancelButtonText={"Cancelar"}
        submitButtonText={"Aceptar"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          setimagenes(files);
          setOpen(false);
        }}
        showPreviewsInDropzone={true}
        showPreviews={false}
        showAlerts={false}
        showFileNamesInPreview={false}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: ver || devolucion,
              Icon: DeleteForever,
              color: "error",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar",
              texto: "",
              funcion: () => {
                seteditar(true);
                setitemHijoEdit(row);
              },
              disabled: ver || devolucion,
              Icon: Create,
              color: "secondary",
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitemHijoEdit(row);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: cargando || botonDisabled(),
              Icon: Check,
              color: "success",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver fotos",
              texto: itemHijoEdit.fotos.length,
              funcion: async () => {
                setOpen(true);
              },
              disabled: false,
              Icon: Photo,
              color: "primary",
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: async () => {
                setexpandido((prev) => !prev);
              },
              disabled: false,
              Icon: expandido ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <Dialog
          open={Openimagen}
          onClose={() => setOpenimagen(false)}
          aria-labelledby="draggable-dialog-title"
        >
          {row.fotos.length === 0 ? null : (
            <MuiImageSlider
              images={row.fotos.map((item) => item.url)}
              fitToImageHeight={true}
            />
          )}
        </Dialog>
        <TableCell size="small" align="center" className={claseCelda}>
          {row.fotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        {editar ? (
          <>
            {" "}
            <TableCell size="small" align="left" className={claseCelda}>
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={bodegaData.map((item) => {
                    return item.bodeNombre + "/+/" + item.bodeId;
                  })}
                  getOptionLabel={(option) => {
                    return option.split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={itemHijoEdit.bodega}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      setitemHijoEdit({
                        ...itemHijoEdit,
                        bodega: options[0],
                        idBodega: options[1],
                      });
                      setproductosData([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            {vestimenta && (
              <TableCell size="small" align="left" className={claseCelda}>
                <TextField
                  fullWidth
                  size="small"
                  id="prodTipo"
                  value={itemHijoEdit.prodTipo}
                  name="prodTipo"
                  onChange={async (e) => {
                    setproductosData([]);
                    setitemHijoEdit({
                      ...itemHijoEdit,
                      prodTipo: e.target.value,
                    });
                  }}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione.</MenuItem>
                  {["VESTIMENTAS", "EQUIPOS"].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
              </TableCell>
            )}
            <TableCell size="small" align="left" className={claseCelda}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={!itemHijoEdit.idBodega && !itemHijoEdit.prodTipo}
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={productosData.map((item) => {
                    return (
                      item.prodNombre +
                      "/+/" +
                      item.prodSerie +
                      "/+/" +
                      item.T_disponible +
                      "/+/" +
                      item.prodId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option
                      .replace("/+/", "** ")
                      .replace("/+/", "** ")
                      .split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={
                    `${itemHijoEdit.prodNombre} / ${itemHijoEdit.prodSerie}`.trim() ===
                    "/"
                      ? ""
                      : `${itemHijoEdit.prodNombre} / ${itemHijoEdit.prodSerie}`
                  }
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      console.log({ newValue });
                      const [, , , prodId] = newValue.split("/+/");

                      const itemF = productosData.find(
                        (itemProducto) => itemProducto.prodId === Number(prodId)
                      );
                      console.log(itemF);

                      setitemHijoEdit({
                        ...itemHijoEdit,
                        prodTipo: itemF.prodTipo,
                        prodSerie: itemF.prodSerie,
                        prodNombre: itemF.prodNombre,
                        prodMarca: itemF.prodMarca,
                        prodModelo: itemF.prodModelo,
                        prodTalla: itemF.prodTalla,
                        prodColor: itemF.prodColor,
                        prodTipoArma: itemF.prodTipoArma,
                        prodCalibre: itemF.prodCalibre,
                        prodMaterial: itemF.prodMaterial,
                        prodPrecioUnitario: itemF.prodPrecioUnitario,
                        cantidad: 1,
                        T_disponible: itemF.T_disponible,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        try {
                          console.log(
                            `/productos/search/para/distribucion?tipo_producto=${itemHijoEdit.prodTipo}&id_bodega=${itemHijoEdit.idBodega}&search=${e.target.value}`
                          );

                          const res = await clienteAxios.get(
                            `/productos/search/para/distribucion?tipo_producto=${itemHijoEdit.prodTipo}&id_bodega=${itemHijoEdit.idBodega}&search=${e.target.value}`
                          );
                          console.log({ res });

                          setproductosData(res.data);
                        } catch (error) {
                          console.log({ error });
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>{" "}
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                fullWidth
                type="number"
                disabled={
                  !itemHijoEdit.idBodega &&
                  !itemHijoEdit.prodTipo &&
                  !itemHijoEdit.prodNombre
                }
                size="small"
                value={itemHijoEdit.cantidad}
                onChange={(e) => {
                  console.log(itemHijoEdit.T_disponible);

                  setitemHijoEdit({
                    ...itemHijoEdit,
                    cantidad: Math.max(
                      Math.min(
                        itemHijoEdit.T_disponible,
                        e.target.valueAsNumber
                      ),
                      1
                    ).toFixed(2),
                  });
                }}
              />
            </TableCell>
            {vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {itemHijoEdit.prodTalla}
              </TableCell>
            )}
            {(vestimenta || armas) && (
              <TableCell size="small" align="center" className={claseCelda}>
                {itemHijoEdit.prodColor}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              ${itemHijoEdit.prodPrecioUnitario}
              {` ** `}$
              {(
                itemHijoEdit.prodPrecioUnitario * itemHijoEdit.cantidad
              ).toFixed(2)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.bodega}
            </TableCell>
            {vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTipo}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              {`${row.prodNombre} / ${row.prodSerie}`}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.cantidad}
            </TableCell>
            {vestimenta && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodTalla}
              </TableCell>
            )}
            {(vestimenta || armas) && (
              <TableCell size="small" align="center" className={claseCelda}>
                {row.prodColor}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              ${row.prodPrecioUnitario}
              {` ** `}$
              {(row.prodPrecioUnitario * itemHijoEdit.cantidad).toFixed(2)}
            </TableCell>
          </>
        )}
      </TableRow>
      {/* <Cantidades
        ver={ver}
        devolucion={devolucion}
        expandido={expandido}
        setexpandido={setexpandido}
        item={itemHijoEdit}
        setitem={setitemHijoEdit}
      /> */}
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
