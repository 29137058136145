import { Paper, Table, TableBody, TableHead } from "@mui/material";
import React, { useMemo } from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import { AddCircle } from "@mui/icons-material";
import RowAgregarVestimenta from "./RowAgregarVestimenta";
import RowVestimenta from "./RowVestimenta";
import { columnsVestimenta, columnsArma, columnsMunicion } from "../../data";

const TablaVestimenta = (props) => {
  const {
    setitem,
    item,
    bodegaData,
    ver,
    devolucion,
    vestimenta,
    armas,
    municion,
  } = props;
  const { distri_productos: productosData } = item;

  const distri_productos = useMemo(() => {
    return productosData.filter(
      (itemP) =>
        (municion && itemP.prodTipo === "MUNICIONES") ||
        (armas && itemP.prodTipo === "ARMAS") ||
        (vestimenta &&
          (itemP.prodTipo === "EQUIPOS" || itemP.prodTipo === "VESTIMENTAS"))
    );
  }, [vestimenta, armas, municion, productosData]);

  const {
    agregandoVestimenta,
    setagregandoVestimenta,
    pageVestimenta,
    // setPageVestimenta,
    rowsPerPageVestimenta,
    // setRowsPerPageVestimenta,
    handleChangePageVestimenta,
    handleChangeRowsPerPageVestimenta,
  } = useSimpleTable("Vestimenta");
  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        count={distri_productos.length}
        rowsPerPage={rowsPerPageVestimenta}
        page={pageVestimenta}
        handleChangePage={handleChangePageVestimenta}
        handleChangeRowsPerPage={handleChangeRowsPerPageVestimenta}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregandoVestimenta(true);
            },
            disabled: ver || devolucion,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TablaCabecera
            columns={
              vestimenta
                ? columnsVestimenta
                : armas
                ? columnsArma
                : municion
                ? columnsMunicion
                : []
            }
            habilitarOrdenar={false}
          />
        </TableHead>

        <TableBody component={"div"}>
          {agregandoVestimenta ? (
            <RowAgregarVestimenta
              bodegaData={bodegaData}
              agregar={agregandoVestimenta}
              setagregar={setagregandoVestimenta}
              setitem={setitem}
              item={item}
              vestimenta={vestimenta}
              armas={armas}
              municion={municion}
            />
          ) : null}
          {distri_productos
            .slice(
              pageVestimenta * rowsPerPageVestimenta,
              pageVestimenta * rowsPerPageVestimenta + rowsPerPageVestimenta
            )
            .map((row, index) => {
              return (
                <RowVestimenta
                  bodegaData={bodegaData}
                  ver={ver}
                  devolucion={devolucion}
                  item={item}
                  setitem={setitem}
                  row={row}
                  index={index}
                  key={row.prod_distri_Id}
                  vestimenta={vestimenta}
                  armas={armas}
                  municion={municion}
                />
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TablaVestimenta;
